import { FluentProvider, webDarkTheme } from '@fluentui/react-components';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';

import React, { useEffect, useState } from 'react';
import { CopilotProvider, useCopilotBloc } from '../copilot/context';
import { CopilotChat } from '../copilot/copilot-chat';
import { PromptsView } from './PromptsView';

const PromptsPageBody = () => {
  const { opened } = useCopilotBloc();
  const [copilotSize, setCopilotSize] = useState(40);

  useEffect(() => {
    const savedSize = localStorage.getItem('copilotPanelSize');
    if (savedSize) {
      setCopilotSize(Number(savedSize));
    }
  }, []);

  const handlePanelResize = (sizes: number[]) => {
    if (sizes.length > 1) {
      const newSize = sizes[1];
      setCopilotSize(newSize);
      localStorage.setItem('copilotPanelSize', newSize.toString());
    }
  };

  return (
    <PanelGroup direction='horizontal' onLayout={handlePanelResize}>
      <Panel id='router' minSize={20} order={1}>
        <PromptsView />
      </Panel>
      {opened && (
        <>
          <PanelResizeHandle className='w-1 bg-border hover:bg-primary' />
          <Panel
            id='copilot'
            minSize={20}
            maxSize={50}
            defaultSize={copilotSize}
            style={{
              display: 'flex',
              flexDirection: 'column',
              borderLeft: '2px solid #1a1a1a',
              width: '100%',
            }}
            order={2}
          >
            <CopilotChat />
          </Panel>
        </>
      )}
    </PanelGroup>
  );
};

export const PromptsPage = () => {
  return (
    <FluentProvider
      theme={webDarkTheme}
      style={{
        backgroundColor: '#212121',
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100vh - 64px)',
        width: '100%',
      }}
    >
      <CopilotProvider>
        <PromptsPageBody />
      </CopilotProvider>
    </FluentProvider>
  );
};
