import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  LinearProgress,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import getAxios from 'apiRestConnector';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { AuthContext } from '../../../services/authContext/AuthContextProvider';

interface Props {
  id: number;
}

interface InfoCandidate {
  nombre: string;
  activo: string;
  id: number;
}

const CandidateList = ({
  status,
  candidates,
  color,
}: {
  status: string;
  candidates: InfoCandidate[];
  color: string;
}) => {
  const activeCandidates = candidates.filter((candidate) => candidate.activo === status);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: 45,
          px: 2,
          borderRadius: 1,
          fontWeight: 800,
          color: '#FFF',
          backgroundColor: color,
        }}
      >
        {`Candidatos ${status}s`}
      </Box>
      <Stack gap={1} sx={{ p: 1 }}>
        {activeCandidates.length > 0 ? (
          activeCandidates.map((candidate) => (
            <Link
              key={candidate.id}
              component={RouterLink}
              to={`/candidate/${candidate.id}/edit`}
              underline='none'
            >
              {candidate.nombre}
            </Link>
          ))
        ) : (
          <Typography>{`Sin candidatos ${status}s`}</Typography>
        )}
      </Stack>
    </>
  );
};

export const FilterCandidates = ({ id }: Props) => {
  const { storeAuth } = useContext(AuthContext)!;
  const { access_token } = storeAuth.user;
  const [infoCandidate, setInfoCandidate] = useState<InfoCandidate[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchNumberCandidates = useCallback(() => {
    const fetchCandidates = async (template: number[]) => {
      const templateFiltrado = new Set(template);
      const candidatosFiltrados = Array.from(templateFiltrado);

      if (template.length > 0) {
        const promises = candidatosFiltrados.map(async (element: number) => {
          try {
            const result = await getAxios.instance(access_token).get(`/personas/${element}`);
            const candidate = {
              nombre: result.data.nombre,
              activo: result.data.activo,
              id: result.data.telefono,
            };

            return candidate;
          } catch (error) {
            console.error(error);
            return null;
          }
        });

        const listCandidate = (await Promise.all(promises)).filter(
          (candidate) => candidate !== null
        );
        setInfoCandidate(listCandidate);
      }
      setLoading(false);
    };

    getAxios
      .instance(access_token)
      .get(`/personas/asociacion/${id}`)
      .then((result: any) => {
        fetchCandidates(result.data);
      });
  }, [access_token, id]);

  useEffect(() => {
    fetchNumberCandidates();
  }, [fetchNumberCandidates]);

  return (
    <>
      {infoCandidate.length > 0 && (
        <Box>
          <Accordion elevation={2}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography component='p' variant='h6'>
                Candidatos
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {loading ? (
                <LinearProgress />
              ) : (
                <Stack>
                  <CandidateList status='activo' candidates={infoCandidate} color='success.main' />
                  <CandidateList status='inactivo' candidates={infoCandidate} color='info.main' />
                </Stack>
              )}
            </AccordionDetails>
          </Accordion>
        </Box>
      )}
    </>
  );
};
