// src/components/ui/calendar/WeekdayTimePicker.tsx

import React, { useState, useEffect } from 'react';
import './WeekdayTimePicker.css'; // Asegúrate de que este archivo CSS esté actualizado
import { Typography, Stack, Button, FormControlLabel, Checkbox } from '@mui/material';
import MuiTimePicker from '../time/MuiTimePicker';
import { Dayjs } from 'dayjs';

type TimeSlot = {
  start: string;
  end: string;
};

type WeekdayBlockTimePickerProps = {
  blockedHoursPerDay: { [key: string]: TimeSlot[] };
  setBlockedHoursPerDay: (blockedHoursPerDay: { [key: string]: TimeSlot[] }) => void;
};

const WeekdayBlockTimePicker: React.FC<WeekdayBlockTimePickerProps> = ({
  blockedHoursPerDay,
  setBlockedHoursPerDay,
}) => {
  const [selectedDays, setSelectedDays] = useState<string[]>([]);
  const [timeSlots, setTimeSlots] = useState<{ [key: string]: TimeSlot[] }>(blockedHoursPerDay);

  const [startTime, setStartTime] = useState<Dayjs | null>(null);
  const [endTime, setEndTime] = useState<Dayjs | null>(null);
  const [error, setError] = useState<string>('');

  const daysOfWeek = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];

  const toggleDay = (day: string) => {
    setSelectedDays((prev) => {
      const updatedDays = prev.includes(day) ? prev.filter((d) => d !== day) : [...prev, day];

      // Ordenar los días seleccionados según daysOfWeek
      return updatedDays.sort((a, b) => daysOfWeek.indexOf(a) - daysOfWeek.indexOf(b));
    });
  };

  useEffect(() => {
    // Solo se ejecuta una vez al montar el componente
    setTimeSlots(blockedHoursPerDay);
    setBlockedHoursPerDay(blockedHoursPerDay);
  }, [blockedHoursPerDay, setBlockedHoursPerDay]);

  useEffect(() => {
    setBlockedHoursPerDay(timeSlots);
  }, [timeSlots, setBlockedHoursPerDay]);

  const addTimeSlot = (event: React.FormEvent) => {
    event.preventDefault();
    if (startTime && endTime && selectedDays.length > 0) {
      if (endTime <= startTime) {
        setError('* La hora de fin debe ser mayor que la hora de inicio.');
        return;
      } else if (startTime === endTime) {
        setError('* La hora de inicio y la hora de fin no pueden ser iguales.');
        return;
      } else {
        setError(''); // Limpiar el error si la validación es correcta
      }

      const startHour = startTime.format('HH:mm'); // Formato solo de horas
      const endHour = endTime.format('HH:mm'); // Formato solo de horas

      selectedDays.forEach((day) => {
        setTimeSlots((prev: { [key: string]: TimeSlot[] }) => ({
          ...prev,
          [day]: [...(prev[day] || []), { start: startHour, end: endHour }],
        }));
      });
      // Limpiar los campos de entrada después de agregar
      setStartTime(null);
      setEndTime(null);
    } else if (startTime === null || endTime === null) {
      setError('* Debes seleccionar una hora de inicio y una hora de fin.');
      return;
    }
  };

  const removeTimeSlot = (day: string, index: number) => {
    setTimeSlots((prev) => {
      const updatedSlots = [...(prev[day] || [])];
      updatedSlots.splice(index, 1); // Eliminar el horario en el índice especificado
      return { ...prev, [day]: updatedSlots };
    });
  };

  return (
    <div className='weekday-time-picker'>
      <Typography variant='h6'>Habilitación de horarios para los días de la semana</Typography>
      <div className='days-container'>
        {daysOfWeek.map((day) => (
          <FormControlLabel
            key={day}
            control={
              <Checkbox
                checked={selectedDays.includes(day)}
                onChange={() => toggleDay(day)}
                color='primary' // Color del checkbox
              />
            }
            label={day} // Etiqueta del día
          />
        ))}
      </div>
      {selectedDays.length > 0 && (
        <>
          <Typography variant='h6'>Selecciona horarios</Typography>
          <Stack direction='row' spacing={2}>
            <MuiTimePicker value={startTime} onChange={(newValue) => setStartTime(newValue)} />
            <MuiTimePicker value={endTime} onChange={(newValue) => setEndTime(newValue)} />

            <Button onClick={addTimeSlot} className={'styled-button'}>
              Agregar Horario
            </Button>
          </Stack>
          <Typography variant='body2' color='white' className='message'>
            * Se agrega el horario seleccionado para los días seleccionados
          </Typography>
          {error && (
            <Typography variant='body2' color='error' className='message'>
              {error}
            </Typography>
          )}
        </>
      )}
      {daysOfWeek.map(
        (
          day // Mostrar siempre los días de la semana
        ) => (
          <div key={day} className='day-schedule'>
            <Typography variant='h6'>Horarios habilitados para {day}:</Typography>
            <ul>
              {timeSlots[day]?.length > 0 ? (
                timeSlots[day].map((slot, index) => (
                  <li
                    key={index}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Typography variant='body1'>
                      {slot.start} - {slot.end}
                    </Typography>
                    <Button
                      onClick={() => removeTimeSlot(day, index)}
                      color='secondary'
                      style={{ marginLeft: '8px' }}
                    >
                      Eliminar
                    </Button>
                  </li>
                ))
              ) : (
                <Typography variant='body1'>No hay horarios habilitados.</Typography>
              )}
            </ul>
          </div>
        )
      )}
    </div>
  );
};

export default WeekdayBlockTimePicker;
