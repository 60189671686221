import React, { createContext, ReactNode, useContext, useEffect, useRef, useState } from 'react';

export type CopilotBloc = {
  opened: boolean;
  toggle: () => void;
  systemPrompt: string;
  setSystemPrompt: (prompt: string) => void;
  userPrompt: string;
  setUserPrompt: (prompt: string) => void;
  reflectionPrompt: string;
  setReflectionPrompt: (prompt: string) => void;
  cvFile: File | null;
  setCvFile: (file: File | null) => void;
  getPromptHook: (
    promptId: string,
    promptType: string
  ) => [string, React.Dispatch<React.SetStateAction<string>>];
  vacante: string;
  setVacante: (vacante: string) => void;
  promptId: string;
  setPromptId: (promptId: string) => void;
  generating: boolean;
  setGenerating: (generating: boolean) => void;
  response: string | null;
  setResponse: React.Dispatch<React.SetStateAction<string | null>>;
  abortControllerRef: React.MutableRefObject<AbortController | null>;
  cancelGeneration: () => void;
};

const CopilotContext = createContext<CopilotBloc | null>(null);

export const useCopilotBloc = () => {
  const context = useContext(CopilotContext);
  if (context === null) {
    throw new Error('useCopilot debe ser usado dentro de un CopilotProvider');
  }
  return context;
};

export const CopilotProvider = ({ children }: { children: ReactNode }) => {
  const [opened, setOpened] = useState(false);
  const [systemPrompt, setSystemPrompt] = useState('');
  const [userPrompt, setUserPrompt] = useState('');
  const [reflectionPrompt, setReflectionPrompt] = useState('');
  const [cvFile, setCvFile] = useState<File | null>(null);
  const [vacante, setVacante] = useState('');
  const [promptId, setPromptId] = useState('');
  const [response, setResponse] = useState<string | null>(null);
  const [generating, setGenerating] = useState(false);

  const abortControllerRef = useRef<AbortController | null>(null);

  useEffect(() => {
    setResponse(null);
    setGenerating(false);
  }, [promptId]);

  const getPromptHook = (
    promptId: string,
    promptType: string
  ): [string, React.Dispatch<React.SetStateAction<string>>] => {
    if (promptId.includes('reflection')) {
      return [reflectionPrompt, setReflectionPrompt];
    }

    if (promptType === 'system') {
      return [systemPrompt, setSystemPrompt];
    }

    return [userPrompt, setUserPrompt];
  };

  return (
    <CopilotContext.Provider
      value={{
        opened,
        toggle: () => setOpened(!opened),
        systemPrompt,
        setSystemPrompt,
        userPrompt,
        setUserPrompt,
        reflectionPrompt,
        setReflectionPrompt,
        cvFile,
        setCvFile,
        getPromptHook,
        vacante,
        setVacante,
        promptId,
        setPromptId,
        response,
        setResponse,
        generating,
        setGenerating,
        abortControllerRef,
        cancelGeneration: () => {
          if (abortControllerRef.current) {
            abortControllerRef.current.abort();
            setResponse('');
            setGenerating(false);
          }
        },
      }}
    >
      {children}
    </CopilotContext.Provider>
  );
};
