import PersonIcon from '@mui/icons-material/Person';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import { Avatar, Box, ListItem, Typography } from '@mui/material';
import React from 'react';

import { formatDate } from 'utils/date';
import { EventItemProps } from '../types';
import { WhatsappBubble } from './WhatsappBubble';

export const EventItemWhatsapp = ({ event }: EventItemProps) => {
  return (
    <ListItem
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems:
          event.origin === 'assistant' || event.origin === 'recruiter' ? 'flex-end' : 'flex-start',
        p: 0.5,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          mb: 1,
          flexDirection:
            event.origin === 'assistant' || event.origin === 'recruiter' ? 'row-reverse' : 'row',
          gap: 1,
        }}
      >
        <Avatar
          sx={{
            bgcolor:
              event.origin === 'assistant' || event.origin === 'recruiter'
                ? 'primary.main'
                : 'secondary.main',
            width: 32,
            height: 32,
          }}
        >
          {event.origin === 'assistant' && <SmartToyIcon />}
          {event.origin === 'recruiter' && <PersonIcon />}
          {event.origin === 'user' && <PersonIcon />}
        </Avatar>
        <Typography variant='caption' sx={{ color: 'text.secondary', fontWeight: 'medium' }}>
          {event.origin === 'assistant' && 'Assistente'}
          {event.origin === 'recruiter' && 'Reclutador'}
          {event.origin === 'user' && 'Candidato'}
        </Typography>
      </Box>
      <WhatsappBubble origin={event.origin as 'user' | 'assistant' | 'recruiter'}>
        <Typography variant='body1'>{event.detail}</Typography>
        <Typography
          variant='caption'
          sx={{
            display: 'block',
            textAlign: 'right',
            mt: 0.5,
            color: (theme) =>
              event.origin === 'assistant' || event.origin === 'recruiter'
                ? theme.palette.primary.contrastText
                : theme.palette.text.secondary,
            fontSize: '0.7rem',
            opacity: event.origin === 'assistant' || event.origin === 'recruiter' ? 0.9 : 0.7,
          }}
        >
          {formatDate(event.createdAt)}
        </Typography>
      </WhatsappBubble>
    </ListItem>
  );
};
