import getAxios from 'apiRestConnector';
import { PromptType } from 'components/models/RequestInterfaces';

const HUNTER_AI_URL = process.env.REACT_APP_HUNTER_AI_URL;
const HUNTER_AI_API_KEY = process.env.REACT_APP_HUNTER_AI_API_KEY;

const httpClient = (accessToken: string) => getAxios.instance(accessToken);

export const getPrompts = async (accessToken: string, requestId: string, promptId: string) => {
  const response = await httpClient(accessToken).get<PromptType>(
    `/solicitudes/${requestId}/prompt/${promptId}`
  );

  if (response.status !== 200) {
    throw new Error('Error al obtener los prompts');
  }

  const { data } = response;

  return data;
};

export const savePrompts = async (
  accessToken: string,
  requestId: string,
  promptId: string,
  prompts: PromptType
) => {
  const response = await httpClient(accessToken).put(
    `/solicitudes/${requestId}/prompt/${promptId}`,
    prompts
  );

  if (response.status !== 200) {
    throw new Error('Error al guardar los prompts');
  }

  return response.data;
};

export const getDefaultPrompt = async (promptId: string, promptType: string) => {
  const response = await fetch(`${HUNTER_AI_URL}/analizar_cv/prompts/${promptId}/${promptType}`, {
    headers: {
      'api-key-auth': HUNTER_AI_API_KEY,
    },
  });

  const prompt = await response.text();

  return prompt.trim();
};

export const getRequest = async (accessToken: string, requestId: string) => {
  const response = await httpClient(accessToken).get<{
    descripcion: string;
    empresa: string;
    rol: string[];
    nivel: string;
  }>(`/solicitudes/${requestId}`);

  if (response.status !== 200) {
    throw new Error('Error al obtener la vacante');
  }

  const { data } = response;

  return data;
};

export const submitPlayground = async (data: FormData, signal?: AbortSignal) => {
  const url = `${HUNTER_AI_URL}/analizar_cv/playground`;
  const response = await fetch(url, {
    method: 'POST',
    body: data,
    headers: {
      'api-key-auth': HUNTER_AI_API_KEY,
    },
    signal,
  });

  if (!response.ok) {
    throw new Error(`Error HTTP: ${response.status}`);
  }

  const reader = response.body.getReader();
  const decoder = new TextDecoder();

  return new ReadableStream({
    async start(controller) {
      try {
        while (true) {
          const { done, value } = await reader.read();

          if (done) {
            controller.close();
            break;
          }

          const text = decoder.decode(value);
          controller.enqueue(text);
        }
      } catch (error) {
        controller.error(error);
      }
    },
    cancel() {
      reader.cancel();
    },
  });
};
