import { submitPlayground } from '../prompt/api';
import { useCopilotBloc } from './context';

export const useCopilotPlayground = () => {
  const {
    cvFile,
    systemPrompt,
    userPrompt,
    reflectionPrompt,
    vacante,
    promptId,
    setResponse,
    setGenerating,
    abortControllerRef,
  } = useCopilotBloc();

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      setResponse('');
      setGenerating(true);
      abortControllerRef.current = new AbortController();

      const formData = new FormData();
      formData.append('prompt_id', promptId);
      formData.append('system', systemPrompt);
      formData.append('user', userPrompt);
      formData.append('reflection', reflectionPrompt);
      formData.append('cv_file', cvFile);
      formData.append('vacante', vacante);

      const stream = await submitPlayground(formData, abortControllerRef.current.signal);
      const reader = stream.getReader();

      while (true) {
        const { done, value } = await reader.read();

        if (done) {
          setGenerating(false);
          break;
        }

        setResponse((prevResponse) => prevResponse + value);
      }
    } catch (error) {
      setResponse(
        (prevResponse) => prevResponse + '\n\nError al procesar la solicitud\n\n' + error.message
      );
      setGenerating(false);
    } finally {
      abortControllerRef.current = null;
    }
  };

  return { submit };
};
