import React, { useEffect, useState } from 'react';
import {
  Paper,
  Box,
  Chip,
  Divider,
  Stack,
  MenuItem,
  Select,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
  FormControl,
  InputLabel,
  Alert,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import MarkdownEditor from '../../../../ui/markdownEditor';
import { filterAvailableStacks, searchCorrectLevel } from './TeamFormStepStack.helpers';
import type { IStackLevel, ITeam, TeamFormStepProps } from '../../CreateTeam.types';
import useTeamStack from 'hooks/useTeamStacks';
import { StackResponse } from 'components/models/TeamStack';

const TeamFormStepStack = ({ teamInfo, setTeamInfo }: TeamFormStepProps) => {
  const [stackInput, setStackInput] = React.useState('');
  const [team, setTeam] = React.useState<StackResponse>();
  const { getTeamStack, getAllStacks } = useTeamStack(teamInfo._id);
  const [stacks, setStacks] = useState();

  useEffect(() => {
    async function getStacks() {
      try {
        const data = await getAllStacks();
        setStacks(data.stack);
        return data.stack;
      } catch (error) {
        console.error(error);
      }
    }
    getStacks();
  }, [getAllStacks]);

  const filteredStacks = filterAvailableStacks({
    searchTerm: stackInput,
    allAvailableStacks: !stacks ? [] : stacks,
    assignedStacks: teamInfo.stack,
  });

  function changeStackLevel(newLevel: string, index: number) {
    const newLevels: IStackLevel[] = teamInfo.stack[index].nivel.map((level) => {
      return {
        ...level,
        valor: level.nombre === newLevel,
      };
    });

    const levelDescription = newLevels.find((level) => level.nombre === newLevel)?.descripcion;

    setTeamInfo((prevTeamInfo) => {
      const newTeamInfo: ITeam = structuredClone(prevTeamInfo);
      newTeamInfo.stack[index].nivel = newLevels;
      newTeamInfo.stack[index].descripcion = levelDescription ?? '';
      return newTeamInfo;
    });
  }

  function changeStackDescription(newDescription: string, index: number) {
    setTeamInfo((prevTeamInfo) => {
      const newTeamInfo: ITeam = structuredClone(prevTeamInfo);
      newTeamInfo.stack[index].descripcion = newDescription;
      return newTeamInfo;
    });
  }
  function removeStack(index: number) {
    setTeamInfo((prevTeamInfo) => {
      const newTeamInfo: ITeam = structuredClone(prevTeamInfo);
      newTeamInfo.stack.splice(index, 1);
      return newTeamInfo;
    });
  }

  function addStack(newStack: any) {
    setTeamInfo((prevTeamInfo) => {
      const newTeamInfo: ITeam = structuredClone(prevTeamInfo);
      const updatedStack = structuredClone(newStack);
      if (!Array.isArray(updatedStack.nivel)) updatedStack.nivel = [];
      const verifyIfExists = newTeamInfo.stack.some(
        (stack) => stack.nombre === updatedStack.nombre
      );
      !verifyIfExists && newTeamInfo.stack.push(updatedStack);
      return newTeamInfo;
    });
  }

  async function orderTeamStackInfo(data: string, event) {
    try {
      const teamData = await getTeamStack(data);
      setTeam(teamData);
      const stacks = Array.isArray(teamData?.stack?.stack) ? teamData?.stack?.stack : [];
      stacks?.forEach((item) => {
        if (!Array.isArray(item.nivel)) item.nivel = [];
        addStack(item);
      });
    } catch (error) {
      console.error('Error al agregar el stack:', error);
    }
  }

  async function onExecutionSearching(event: any) {
    event.preventDefault();
    try {
      if (event.key === 'Enter') {
        await orderTeamStackInfo(event.target.value, event);
      }
      setStackInput(event.target.value);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Box sx={{ minHeight: '500px' }}>
      <Box sx={{ mt: '20px' }}>
        <Typography variant='h4' gutterBottom>
          Stack tecnológico
        </Typography>
        <Typography>
          Define las herramientas y tecnologías con las que trabajará el equipo.
        </Typography>
      </Box>
      <Divider sx={{ mb: '20px', mt: '10px' }} />
      <Stack alignItems='center' minHeight={200}>
        <TextField
          label='Tecnologías'
          variant='outlined'
          value={stackInput}
          onChange={async (event) => {
            await onExecutionSearching(event);
          }}
          onKeyUp={async (event) => {
            await onExecutionSearching(event);
          }}
          fullWidth
          sx={{ maxWidth: 560, mb: '10px', mt: '30px' }}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  title='Limpiar'
                  onClick={() => {
                    setStackInput('');
                    setTeam(null);
                  }}
                >
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Box>
          {team?.message ? (
            <Chip label={team?.message} color='warning' variant='outlined' sx={{ mr: '10px' }} />
          ) : (
            filteredStacks.map((filteredStack, index) => {
              if (index < 10 && stackInput.length > 1) {
                return (
                  <Chip
                    key={index}
                    label={filteredStack.nombre}
                    variant='outlined'
                    onClick={async (event) => {
                      event.preventDefault();
                    }}
                    sx={{ mr: '10px' }}
                  />
                );
              } else {
                return null;
              }
            })
          )}
        </Box>
        <Box>
          {team?.code === 201 && (
            <>
              <Alert severity='success'>Se ha agregado el Stack exitosamente.</Alert>
            </>
          )}
        </Box>
      </Stack>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(500px, 1fr))',
          gap: 2,
          mb: 4,
        }}
      >
        {teamInfo.stack.map((stack, stackIndex) => {
          return (
            <Paper key={stack.nombre} elevation={2} sx={{ p: 2 }}>
              <Stack gap={2}>
                <Stack direction='row' alignItems='center' justifyContent='space-between'>
                  <Typography fontWeight='bold'>{stack.nombre}</Typography>
                  <IconButton
                    title='Eliminar'
                    onClick={() => {
                      removeStack(stackIndex);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Stack>
                <FormControl fullWidth>
                  <InputLabel>Nivel</InputLabel>
                  <Select
                    label='Nivel'
                    value={searchCorrectLevel(stack)?.nombre ?? ''}
                    onChange={(e) => {
                      changeStackLevel(e.target.value, stackIndex);
                    }}
                    size='small'
                  >
                    {stack.nivel.map((level, levelIndex) => {
                      return (
                        <MenuItem key={levelIndex} value={level.nombre}>
                          {level.nombre}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <MarkdownEditor
                  placeholder='Descripción'
                  value={stack.descripcion}
                  onChange={(newValue) => {
                    changeStackDescription(newValue, stackIndex);
                  }}
                />
              </Stack>
            </Paper>
          );
        })}
      </Box>
    </Box>
  );
};

export default TeamFormStepStack;
