import * as React from 'react';
import { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

type MuiTimePickerProps = {
  value: Dayjs | null;
  onChange: (newValue: Dayjs | null) => void;
};

export default function TimePickerValue({ value, onChange }: MuiTimePickerProps) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['TimePicker', 'TimePicker']}>
        <TimePicker
          label='Selecciona hora'
          value={value}
          onChange={(newValue) => onChange(newValue)}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}
