import { ListItemText, Typography } from '@mui/material';
import SVGCalendar from 'components/ui/icons/SVGCalendar';
import { DateTime } from 'luxon';
import React from 'react';
import {
  StyledContainerDark,
  StyledContainerlight,
  StyledListItem,
  StyledSpanDescription,
} from './style';
import { WorkflowEventWithUser } from './useEventNotifications';

export type EventNotificationItemProps = {
  event: WorkflowEventWithUser;
};

const getEventOrigin = (origin: 'assistant' | 'user' | 'server' | 'recruiter') => {
  switch (origin) {
    case 'assistant':
      return '🤖';
    case 'recruiter':
      return '🕵️‍♂️';
    case 'user':
      return '👨🏻‍💻';
  }

  return '💻';
};

export const EventNotificationItem = ({ event }: EventNotificationItemProps) => {
  return (
    <a
      key={event._id}
      href={`/candidate/${event.telefono}/edit`}
      style={{ textDecoration: 'none' }}
    >
      <div style={{ margin: 'auto 15px', position: 'relative' }}>
        <StyledContainerDark>
          <StyledContainerlight>
            <StyledListItem alignItems='flex-start'>
              <ListItemText
                sx={{ width: 'inherit', marginTop: '7px' }}
                primary={
                  <h3
                    style={{
                      color: '#31CFF2',
                      fontSize: '16px',
                      textDecoration: 'none !important',
                      fontWeight: 'bold',
                    }}
                  >
                    {getEventOrigin(event.origin)} {event.user}
                  </h3>
                }
                secondary={
                  <StyledSpanDescription>
                    <Typography
                      sx={{ color: 'white', margin: '10px 0 10px' }}
                      component='span'
                      variant='body2'
                      color='text.primary'
                    >
                      {event.detail}
                    </Typography>
                  </StyledSpanDescription>
                }
              />
            </StyledListItem>
          </StyledContainerlight>
          <div
            title={DateTime.fromISO(event.createdAt).toLocaleString({
              ...DateTime.DATETIME_FULL,
              weekday: 'long',
            })}
            style={{
              padding: '4px 8px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div>
              <SVGCalendar width={15} height={15} color={'#637DAF'} />
              <Typography
                sx={{ color: '#637DAF', fontSize: '12px', marginLeft: '0.5rem' }}
                component='span'
              >
                {DateTime.fromISO(event.createdAt).toRelative()}
              </Typography>
            </div>
          </div>
        </StyledContainerDark>
      </div>
    </a>
  );
};
