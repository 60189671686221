import { Spinner, Text } from '@fluentui/react-components';
import { ArrowExitFilled, RecordStopRegular } from '@fluentui/react-icons';
import Markdown from 'markdown-to-jsx';
import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { promptsMapper } from '../prompt/prompts';
import { useCopilotBloc } from './context';
import { CvFileForm } from './cvfile-form';

const CopilotChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const CopilotChatHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
`;

const IconButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-radius: 4px;
  background-color: #1a1a1a;
  border: none;
  cursor: pointer;
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: #1a1a1a;
`;

const CopilotChatBody = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 12px;
  gap: 14px;
  overflow: auto;
  scroll-behavior: smooth;
`;

export const CopilotChat = () => {
  const { promptId } = useParams<{ promptId: string }>();
  const { toggle, response, generating, cancelGeneration } = useCopilotBloc();

  const chatBodyRef = useRef<HTMLDivElement>(null);
  const [shouldAutoScroll, setShouldAutoScroll] = React.useState(true);

  useEffect(() => {
    if (chatBodyRef.current && shouldAutoScroll) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  }, [response, generating, shouldAutoScroll]);

  const handleScroll = () => {
    if (chatBodyRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = chatBodyRef.current;
      const isAtBottom = Math.abs(scrollHeight - scrollTop - clientHeight) < 45;
      setShouldAutoScroll(isAtBottom);
    }
  };

  const prompt = promptsMapper[promptId];

  return (
    <CopilotChatContainer>
      <CopilotChatHeader>
        <Text>{prompt.title}</Text>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
          <IconButton
            onClick={() => {
              cancelGeneration();
            }}
          >
            <RecordStopRegular />
          </IconButton>
          <IconButton
            onClick={() => {
              cancelGeneration();
              toggle();
            }}
          >
            <ArrowExitFilled />
          </IconButton>
        </div>
      </CopilotChatHeader>
      <Divider />
      <CopilotChatBody ref={chatBodyRef} onScroll={handleScroll}>
        <CvFileForm />
        {response && <Markdown>{response}</Markdown>}
        {generating && <Spinner />}
      </CopilotChatBody>
    </CopilotChatContainer>
  );
};
