import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { PromptType } from 'components/models/RequestInterfaces';
import React, { useContext, useEffect } from 'react';
import { AuthContext } from 'services/authContext/AuthContextProvider';
import { useCopilotBloc } from '../copilot/context';
import { getDefaultPrompt, getPrompts, getRequest, savePrompts } from './api';

export const useRequest = (requestId: string) => {
  const { storeAuth } = useContext(AuthContext)!;
  const { access_token } = storeAuth.user;
  const { setVacante } = useCopilotBloc();

  const { data } = useQuery({
    // eslint-disable-next-line
    queryKey: ['vacante', requestId],
    queryFn: () => getRequest(access_token, requestId),
  });

  React.useEffect(() => {
    if (data) {
      setVacante(data.descripcion);
    }
  }, [data, setVacante]);

  return data;
};

export const usePrompts = (requestId: string, promptId: string) => {
  const { storeAuth } = useContext(AuthContext)!;
  const { access_token } = storeAuth.user;

  const { data, isLoading, error } = useQuery({
    // eslint-disable-next-line
    queryKey: ['prompts', requestId, promptId],
    queryFn: () => getPrompts(access_token, requestId, promptId),
  });

  return { data, isLoading, error };
};

export const useSavePrompts = (requestId: string, promptId: string) => {
  const { storeAuth } = useContext(AuthContext)!;
  const { access_token } = storeAuth.user;
  const queryClient = useQueryClient();

  const { mutate, isPending, error } = useMutation({
    mutationKey: ['savePrompts', requestId, promptId],
    mutationFn: (prompts: PromptType) => savePrompts(access_token, requestId, promptId, prompts),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['prompts', requestId, promptId] });
    },
  });

  return { savePrompts: mutate, savingPrompts: isPending, errorSavingPrompts: error };
};

export const usePromptValue = (promptId: string, promptType: string, defaultValue: string) => {
  const { getPromptHook } = useCopilotBloc();
  const [value, setValue] = getPromptHook(promptId, promptType);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue, setValue]);

  const { mutate } = useMutation({
    mutationKey: ['defaultPrompt', promptId, promptType],
    mutationFn: () => getDefaultPrompt(promptId, promptType),
    onSuccess: setValue,
  });

  return { restart: () => mutate(), value, setValue };
};
