import MessageIcon from '@mui/icons-material/Message';
import { Button, ButtonBase, CircularProgress, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { EventNotificationItem } from './EventNotificationItem';
import { StyledDivImage, StyledLastDiv, StyledListNotifications } from './style';
import { useEventNotifications } from './useEventNotifications';

export const EventsNotifications = () => {
  const [display, setDisplay] = useState(false);
  const { events, fetchNextPage, isFetchingNextPage, hasNextPage } = useEventNotifications();

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const element = e.target as HTMLDivElement;
    if (element.scrollHeight - element.scrollTop <= element.clientHeight + 1) {
      fetchNextPage();
    }
  };

  return (
    <ButtonBase
      component='div'
      className='notification-wrapper'
      sx={{
        position: 'relative',
        width: '50px',
        height: '50px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexShrink: 0,
        borderRadius: '50%',
      }}
    >
      <StyledDivImage
        onClick={(e) => {
          setDisplay(!display);
        }}
      >
        <Tooltip title='Mensajes y eventos'>
          <MessageIcon sx={{ color: 'white' }} />
        </Tooltip>
      </StyledDivImage>
      {events && (
        <StyledListNotifications style={{ display: display ? 'flex' : 'none' }}>
          <div style={{ overflow: 'auto', overscrollBehavior: 'contain' }} onScroll={handleScroll}>
            {events.map((e) => (
              <EventNotificationItem key={e._id} event={e} />
            ))}
            {hasNextPage && (
              <StyledLastDiv>
                {isFetchingNextPage ? (
                  <Button
                    sx={{ fontWeight: '600' }}
                    color='secondary'
                    onClick={() => fetchNextPage()}
                    size='small'
                    variant='contained'
                  >
                    Ver más
                  </Button>
                ) : (
                  <CircularProgress sx={{ margin: 'auto', color: '#31CFF2' }} />
                )}
              </StyledLastDiv>
            )}
            {!hasNextPage && <StyledLastDiv>Estás al día</StyledLastDiv>}
          </div>
        </StyledListNotifications>
      )}
    </ButtonBase>
  );
};
