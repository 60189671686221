export type PromptTypeUI = {
  title: string;
  description: string;
  value: string;
};

export const prompts: PromptTypeUI[] = [
  {
    title: 'Seniority',
    description: 'Evaluar la experiencia profesional del candidato',
    value: 'seniority',
  },
  {
    title: 'Stack',
    description: 'Evaluar el stack del candidato',
    value: 'stack',
  },
  {
    title: 'Estudios',
    description: 'Evaluar los estudios del candidato',
    value: 'studies',
  },
  {
    title: 'Lagunas laborales',
    description: 'Evaluar las lagunas laborales del candidato',
    value: 'job_gaps',
  },
  {
    title: 'Estabilidad laboral',
    description: 'Evaluar la estabilidad laboral del candidato',
    value: 'job_stability',
  },
];

export const promptsMapper = {
  seniority: prompts[0],
  stack: prompts[1],
  studies: prompts[2],
  job_gaps: prompts[3],
  job_stability: prompts[4],
};
