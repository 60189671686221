import { Box, Tooltip as MuiTooltip } from '@mui/material';
import { EstadosCandidateInterface } from 'components/models/requestCandidate';
import React, { FC, useEffect, useState } from 'react';
import { StyledDivTooltip, StyledTaskCardBar, StyledTaskCardCircleBar } from './style';

const AssociateCandidates: FC<Props> = ({ candidatesArray }) => {
  const [namesCandidates, setNamesCandidates] =
    useState<EstadosCandidateInterface[]>(candidatesArray);

  useEffect(() => {
    setNamesCandidates(candidatesArray);
  }, [candidatesArray]);

  const cantidadCandidatos = (estadoWorkflow: string, estados: EstadosCandidateInterface[]) => {
    let contador = 0;
    estados.forEach((estado: EstadosCandidateInterface) => {
      if (estado.nombre === estadoWorkflow) {
        contador = estado.candidatos.length;
      }
    });
    return contador;
  };

  const renderTooltipContent = (estado: string, candidatos: EstadosCandidateInterface[]) => (
    <StyledDivTooltip>
      {candidatos.map(
        (e: EstadosCandidateInterface) =>
          e?.nombre === estado &&
          e.candidatos.map((candidato, index) => (
            <div
              key={index}
              style={{ display: 'flex', textAlign: 'left', padding: '4px 8px 4px 4px' }}
            >
              {candidato}
            </div>
          ))
      )}
    </StyledDivTooltip>
  );

  const estados = [
    { estado: ' inicial', label: 'Inicial' },
    { estado: 'Soft Skills', label: 'Soft Skills' },
    { estado: 'Técnica', label: 'Técnica' },
    { estado: 'Cliente', label: 'Cliente' },
    { estado: 'Contratado', label: 'Contratado' },
  ];

  return (
    <Box sx={{ margin: '1rem 0' }}>
      <StyledTaskCardBar>
        {estados.map((item) => (
          <MuiTooltip
            key={item.estado}
            placement='bottom'
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: 'background.paper',
                  color: 'text.primary',
                  '& .MuiTooltip-arrow': {
                    color: 'background.paper',
                  },
                  boxShadow: 1,
                  borderRadius: 1,
                  p: 1,
                },
              },
            }}
            title={renderTooltipContent(item.estado, namesCandidates)}
          >
            <StyledTaskCardCircleBar
              style={cantidadCandidatos(item.estado, namesCandidates) ? active : inactive}
            >
              {cantidadCandidatos(item.estado, namesCandidates)}
            </StyledTaskCardCircleBar>
          </MuiTooltip>
        ))}
      </StyledTaskCardBar>
    </Box>
  );
};

export default AssociateCandidates;

interface Props {
  candidatesArray?: EstadosCandidateInterface[];
}

const active = {
  background: 'rgb(255 197 121)',
};

const inactive = {
  color: 'black',
};
