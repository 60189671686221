import React from 'react';

import {
  Button,
  SelectTabData,
  SelectTabEvent,
  SelectTabEventHandler,
  Tab,
  TabList,
  Text,
} from '@fluentui/react-components';
import { ArrowExitFilled, PlayCircleSparkleRegular } from '@fluentui/react-icons';
import { useHistory, useParams } from 'react-router-dom';
import { useCopilotBloc } from '../copilot/context';
import { useRequest } from './hooks';
import { prompts } from './prompts';
import { PromptsForm } from './PromptsForm';

type PageParams = {
  requestId: string;
  promptId: string;
};

export const PromptsView = () => {
  const history = useHistory();
  const { opened, toggle, setPromptId, cancelGeneration } = useCopilotBloc();
  const { requestId, promptId } = useParams<PageParams>();
  const request = useRequest(requestId);

  React.useEffect(() => {
    setPromptId(promptId);
  }, [promptId, setPromptId]);

  const handleTabSelect: SelectTabEventHandler = (_: SelectTabEvent, data: SelectTabData) => {
    cancelGeneration();
    const { value } = data;
    history.replace(`/request/${requestId}/editar/prompt/${value}`);
  };

  const requestName = `${request?.empresa} - ${request?.rol.join(', ')} - ${request?.nivel}`;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        gap: '12px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '12px',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Text size={500}>Prompts Playground / {requestName}</Text>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '12px' }}>
          <Button
            icon={<ArrowExitFilled />}
            onClick={() => {
              cancelGeneration();
              history.replace(`/request/${requestId}/editar`);
            }}
          >
            Volver
          </Button>
          {!opened && (
            <Button icon={<PlayCircleSparkleRegular />} appearance='primary' onClick={toggle}>
              <Text>Probar prompt</Text>
            </Button>
          )}
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: '100%',
          gap: '14px',
          overflow: 'hidden',
        }}
      >
        <TabList selectedValue={promptId} onTabSelect={handleTabSelect}>
          {prompts.map((prompt) => (
            <Tab key={prompt.value} value={prompt.value}>
              {prompt.title}
            </Tab>
          ))}
        </TabList>
        <div style={{ flex: 1, overflow: 'auto', height: '100%' }}>
          <PromptsForm requestId={requestId} promptId={promptId} />
        </div>
      </div>
    </div>
  );
};
