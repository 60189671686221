import { Text } from '@fluentui/react-components';
import { ErrorCircleRegular } from '@fluentui/react-icons';
import React from 'react';

export const PromptError = ({ error }: { error: Error }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <ErrorCircleRegular fontSize={64} />
      <Text size={400}>{error.message}</Text>
    </div>
  );
};
