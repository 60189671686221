import { Button, Field, Input, Spinner, Text } from '@fluentui/react-components';

import { PromptType } from 'components/models/RequestInterfaces';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { usePrompts, useSavePrompts } from './hooks';
import { PromptError } from './PromptError';
import { PromptInput } from './PromptInput';
import { PromptLoading } from './PromptLoading';
import { promptsMapper } from './prompts';

export type PromptFormProps = {
  requestId: string;
  promptId: string;
};

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

const Spacer = styled.div`
  flex: 1;
  height: 100%;
`;

export const PromptsForm = ({ requestId, promptId }: PromptFormProps) => {
  const { data, isLoading, error } = usePrompts(requestId, promptId);
  const { savePrompts, savingPrompts, errorSavingPrompts } = useSavePrompts(requestId, promptId);
  const history = useHistory();

  const handleCancel = () => {
    history.push(`/request/${requestId}/editar`);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.target as HTMLFormElement);
    const data = Object.fromEntries(formData.entries());
    const prompts: PromptType = {
      system: data.system as string,
      user: data.user as string,
      factor: Number(data.factor),
      nota_de_corte: Number(data.nota_de_corte),
      reflection: data.reflection as string,
    };
    savePrompts(prompts);
  };

  if (isLoading) {
    return <PromptLoading />;
  }

  if (error) {
    return <PromptError error={error} />;
  }

  if (errorSavingPrompts) {
    return <PromptError error={errorSavingPrompts} />;
  }

  return (
    <form
      key={`${requestId}-${promptId}`}
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
        width: '100%',
        height: '100%',
        padding: '12px',
      }}
      onSubmit={handleSubmit}
    >
      <Row style={{ justifyContent: 'space-between' }}>
        <div>
          <Text size={400}>{promptsMapper[promptId].description}</Text>
        </div>
        <Row style={{ gap: '32px' }}>
          <Field hint='Factor (% Peso en la evaluación final)'>
            <Input name='factor' type='number' defaultValue={`${data?.factor}`} />
          </Field>
          <Field hint='Nota de corte'>
            <Input name='nota_de_corte' type='number' defaultValue={`${data?.nota_de_corte}`} />
          </Field>
        </Row>
      </Row>
      <PromptInput
        name='system'
        promptId={promptId}
        promptType='system'
        label='System Prompt'
        defaultValue={data?.system ?? ''}
        rows={12}
      />
      <PromptInput
        name='user'
        promptId={promptId}
        promptType='user'
        label='User Prompt'
        defaultValue={data?.user ?? ''}
        rows={4}
      />
      {!promptId.includes('job') && (
        <PromptInput
          name='reflection'
          promptId={`${promptId}_reflection`}
          promptType='system'
          label='Reflection Prompt'
          defaultValue={data?.reflection ?? ''}
          rows={8}
        />
      )}
      <Spacer />
      <div
        style={{ display: 'flex', justifyContent: 'flex-end', gap: '12px', marginBottom: '16px' }}
      >
        <Button appearance='secondary' onClick={handleCancel}>
          Cancelar
        </Button>
        <Button appearance='primary' type='submit' disabled={savingPrompts}>
          {savingPrompts ? <Spinner size='tiny' /> : 'Guardar'}
        </Button>
      </div>
    </form>
  );
};
