import { Button, CompoundButton, Spinner, Text } from '@fluentui/react-components';
import { DocumentPdfRegular, PlayCircleSparkleRegular } from '@fluentui/react-icons';
import Markdown from 'markdown-to-jsx';
import React, { useRef } from 'react';
import { styled } from 'styled-components';
import { useCopilotBloc } from './context';
import { useCopilotPlayground } from './hooks';

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const formatBytes = (bytes: number) => {
  const MB = `${(bytes / 1024 / 1024).toFixed(2)} MB`;
  const KB = `${(bytes / 1024).toFixed(2)} KB`;
  return MB === '0.00 MB' ? KB : MB;
};

export const CvFileForm = () => {
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const { cvFile, setCvFile, vacante, generating } = useCopilotBloc();
  const { submit } = useCopilotPlayground();

  const selectFile = () => {
    inputFileRef.current?.click();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCvFile(e.target.files?.[0] || null);
  };

  return (
    <form style={{ display: 'flex', flexDirection: 'column', gap: '16px' }} onSubmit={submit}>
      <Column>
        <Text size={400} weight='bold'>
          Texto de la vacante
        </Text>
        <Markdown>{vacante}</Markdown>
      </Column>
      <Column>
        <Text size={400} weight='bold'>
          CV del candidato
        </Text>
        <input
          ref={inputFileRef}
          type='file'
          accept='application/pdf'
          style={{ display: 'none' }}
          onChange={handleChange}
        />
        <CompoundButton
          icon={<DocumentPdfRegular />}
          onClick={selectFile}
          secondaryContent={cvFile?.size ? formatBytes(cvFile.size) : ''}
        >
          {cvFile?.name ?? 'Seleccionar archivo'}
        </CompoundButton>
      </Column>
      <Button
        appearance='primary'
        icon={generating ? <Spinner size='tiny' /> : <PlayCircleSparkleRegular />}
        type='submit'
        disabled={!cvFile || generating}
      >
        {generating ? 'Generando...' : 'Probar prompt'}
      </Button>
    </form>
  );
};
