import Axios, { AxiosError, AxiosRequestConfig } from 'axios';

// Dev mode in localhost and port.
const BACK_END_URL = process.env.REACT_APP_BACKEND_URL;
const LOG_AXIOS = false;

const isInternalAppOrigin = (origin: string) => origin === BACK_END_URL;

const getAxios = {
  instance: (token = '', url = BACK_END_URL, proxyUrl = '') => {
    const config: AxiosRequestConfig = {
      headers: {},
    };

    config.baseURL = `${proxyUrl}${url}`;

    if (token !== '') {
      config.headers = { Authorization: `Bearer ${token}` };
    }

    const instance = Axios.create(config);

    if (LOG_AXIOS) {
      instance.interceptors.request.use((request: any) => {
        // tslint:disable-next-line: no-console
        console.log('Starting Request to', request.url);
        // tslint:disable-next-line: no-console
        console.log('Request params', request.params);
        // tslint:disable-next-line: no-console
        console.log('Request data', request.data);
        return request;
      });

      instance.interceptors.response.use((response: any) => {
        // tslint:disable-next-line: no-console
        console.log('Response headers:', response.headers);
        // tslint:disable-next-line: no-console
        console.log('Response data:', response.data);
        return response;
      });
    }

    instance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response) {
          switch (error.response.status) {
            case 401:
              const isInternalOrigin = isInternalAppOrigin(error.response.config.baseURL);
              if (isInternalOrigin) {
                localStorage.removeItem('user');
                localStorage.removeItem('token');
                localStorage.removeItem('isAuth');
                window.location.href = '/';
              }
              break;
            case 403:
              console.warn('Acceso no autorizado');
              break;
            case 404:
              console.warn('Recurso no disponible');
              break;
            default:
              console.warn('Error en la conexión');
              break;
          }
          throw error;
        }
      }
    );

    return instance;
  },
  error: (error: AxiosError) => {
    if (error.response) {
      // tslint:disable-next-line: no-console
      console.log('Status', error.response.status, error.response.data);
      throw new Error(`${error.config.url}: ${error.response.status} ${error.response.data}`);
    } else if (error.request) {
      // tslint:disable-next-line: no-console
      console.log('Request', error.request);
    } else {
      // tslint:disable-next-line: no-console
      console.log('Error', error.message);
    }
  },
};

export default getAxios;
