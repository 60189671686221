import getAxios from 'apiRestConnector';
import { StackResponse } from 'components/models/TeamStack';
import { useContext, useState } from 'react';
import { AuthContext } from 'services/authContext/AuthContextProvider';
const { REACT_APP_BACKEND_URL } = process.env;

const useTeamStack = (teamId: string) => {
  const { storeAuth } = useContext(AuthContext)!;
  const { access_token } = storeAuth.user;
  const [teamStack, setTeamStack] = useState<StackResponse>();
  const [stacks, setStacks] = useState<StackResponse>();

  async function getTeamStack(stackId: string): Promise<StackResponse> {
    try {
      const response = await getAxios
        .instance(access_token)
        .get(`${REACT_APP_BACKEND_URL}/team/${teamId}/stack/${stackId}`);
      if (!response) {
        setTeamStack(response.data);
      }
      setTeamStack(response.data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  async function getAllStacks(): Promise<StackResponse> {
    try {
      const response = await getAxios
        .instance(access_token)
        .get(`${REACT_APP_BACKEND_URL}/team/stacks`);
      if (!response) {
        setStacks(response.data);
      }
      setStacks(response.data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  return {
    stacks,
    teamStack,
    setTeamStack,
    getTeamStack,
    getAllStacks,
  };
};

export default useTeamStack;
