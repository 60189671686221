import { Button, Label, Textarea } from '@fluentui/react-components';
import { ArrowRepeatAllFilled } from '@fluentui/react-icons';
import React from 'react';
import styled from 'styled-components';
import { usePromptValue } from './hooks';

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const PromptInput = ({
  label,
  name,
  promptId,
  promptType,
  defaultValue,
  rows,
}: {
  label: string;
  name: string;
  promptId: string;
  promptType: string;
  defaultValue: string;
  rows?: number;
}) => {
  const { restart, value, setValue } = usePromptValue(promptId, promptType, defaultValue);

  return (
    <Column>
      <div style={{ display: 'flex', flexDirection: 'row', gap: '16px', alignItems: 'center' }}>
        <Label>
          <strong>{label}</strong>
        </Label>
        <Button
          style={{ display: 'flex', flexDirection: 'row', gap: '6px' }}
          size='small'
          onClick={restart}
        >
          <ArrowRepeatAllFilled />
          <span>Reiniciar</span>
        </Button>
      </div>
      <Textarea
        name={name}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        rows={rows ?? 1}
        resize='vertical'
      />
    </Column>
  );
};
